<template>
  <div>
    <list-template
      :search-config="searchConfig"
      @onSearch="onSearch"
      @onReset="onReset"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="page"
      @onChangePage="handleCurrentChange"
      @onHandle="handleClick"
      @onChange="onChange"
      @onBatchImport ="handBatchImport "
    >
    </list-template>
    <Dialog :visible.sync="visible" title="导入数据" size="small" buttonAlign="center" submitText="关闭" :showCancelButton="false" :showFooter="true" :closeOnClickModal="false">
      <div class="px-2">
        <el-form ref="form" :model="formData" :rules="rules" label-position="left" label-width="120rem" >
          <el-form-item label="参考城市" prop="city">
            <el-cascader v-model="formData.city" :options="areaOptions"></el-cascader>
          </el-form-item>
          <el-form-item label="下载模板">
            <el-button size="small" icon="el-icon-download" @click="handleSubmit(0)">下载</el-button>
          </el-form-item>
          <el-form-item label="上传数据">
            <el-button type="primary" size="small" icon="el-icon-upload2" @click="handleSubmit(1)">上传</el-button>
          </el-form-item>
        </el-form>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "@/components/common/Dialog"
import { tableListMixin, getAreaDataMixin } from '@/utils/mixins'
import { zsPost, fileDownload } from '@/api'
import { handleChooseFile } from "@/utils"
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  mixins: [tableListMixin,getAreaDataMixin],
  components: { Dialog },
  created() {
    this.getAreaData()
  },
  methods: {
    getData() {
      this.getDataApi('/zhaosheng/api/recruit-2024/exam/list-student-policy')
    },
    handleClick(row, handle) {
      const { type } = handle
      if(type === 'delete') {
        this.$confirm('是否继续删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          zsPost('/recruit-2024/exam/del-student-policy', { id: row.id }).then(() => {
            this.$message.success('删除成功')
            this.getData()
          })
        })
      }
    },
    handBatchImport () {
      this.visible = true
    },
    handleSubmit(type) {
      if(type) {
        this.handleSelectFile()
        this.$refs['form'].clearValidate(['city'])
        return
      }
      this.$refs['form'].validate(valid => {
        if(valid) {
          const city_code = this.formData.city[1]
          this.handleDownFile(city_code)
        }
      })
    },
    handleSelectFile() {
      handleChooseFile({
        api: '/zhaosheng/api/recruit-2024/exam/import-diag-tmpl',
        show: true,
        onSuccess: (res) => {
          this.$message.success('导入成功')
          this.visible = false
          this.getData()
        }
      })
    },
    handleDownFile(city_code) {
      const config = { name: '2024容缺政策人员 ', type: 'xlsx' }
      fileDownload('/zhaosheng/api/recruit-2024/exam/export-diag-tmpl', {city_code}, config)
    }
  },
  data() {
    return {
      visible: false,
      formData: { city: '' },
      rules: {
        city: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      tableConfig: [
        { prop: 'student_name', label: '学生姓名' },
        { prop: 'id_card', label: '学生身份证号' },
        { prop: 'creator', label: '创建人' },
        { prop: 'created_at', label: '创建时间' },
        { label: '操作', width: '110rem', handle: true }
      ],
      searchConfig: [
        { tag: 'input', placeholder: '搜索姓名', prop: 'student_name' }
      ]
    }
  },
}
</script>

<style lang="scss" scoped></style>
